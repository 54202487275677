.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

body {
  padding: 0;
  color: #333;
  /*font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;*/
  font-family: "SF Mono", "Monaco", "Andale Mono", "Lucida Console", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
}
.video.container-fluid {
  padding-bottom: 20vh;
}
button.btn {
  /*border-radius: 255px 25px 225px 25px/25px 225px 25px 255px;*/
}
hr {
  border-top: 1px dashed #333;
}
a:hover sup {text-decoration: none};
tr > td, tr > th
{
  vertical-align: middle !important;
  border-bottom: 1px solid #333 !important;
}
.nav, .pagination, .carousel, .panel-title a, a {
    cursor: pointer;
}

/* Everything but the jumbotron gets side spacing for mobile first views */
.footer {
  padding-left: 15px;
  padding-right: 15px;
}

/* Custom page footer */
.footer {
  padding-top: 19px;
  color: #777;
  border-top: 1px solid #e5e5e5;
}

.inline-element {
  display: inline-block;
}

.media-contents {
  margin-top: 25px;
}

.btn-logout {
  margin-top: 25px;
}

video:hover {
  cursor: pointer;
}

.video-card {
  padding: 10px;
  border-radius: 3px;
  margin: -10px;
  margin-bottom: 20px;
  font-size: 12px;
}

.container-narrow > hr {
  margin: 30px 0;
}

.container-history {
  width: 1170px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.popup {
  margin: 50px auto;
  max-width: 400px;
}

.pagination {
  margin-top: 5px 15px 25px;
  font-size: 16px;
}

.error-message {
  color: #d9534f;
  padding: 5px 2px;
}

.next, .previous {
  cursor: pointer;
}

.form-group > input {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #333;
  border-radius: 4px;
}
.form-group > select {
  border: 1px solid #333;
}


.form-group > button {
  background-color: #ff7043;
  border-color: #ff7374;
  color: #fff;
  width: 100%;
  margin: 10px 0 0;
}

.form-group > btn:hover, .form-group > .btn:active, .form-group > .btn:focus, .form-group > .btn:active:focus {
  background-color: #ffab00;
  border-color: #f69d6e;
  color: #fff !important;
}
.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  opacity: 1;
}

.navbar .btn-nav{
  color: #fff !important;
  padding: 4px 6px !important;
  margin: 10px;
  /*border-radius: 255px 25px 225px 25px/25px 225px 25px 255px;*/
}
.navbar .btn-nav:hover, .btn-nav:active:focus{
  background-color: #3b73af;
}

.username {
  padding: 15px;
  display: inline-block;
  color: #fff !important;

}

.need-signup > span, .session-link > span {
    cursor: pointer;
    font-size: 14px;
    font-weight: 300;
}

.need-signup .link-highlite {
  color: #ff7043;
  margin-left: 0.5em;
}
.need-signup .link-highlite:hover {
  text-decoration: underline;
}

.session-link {
    color: #3b73af;
}
.session-link:hover {
  text-decoration: underline;
}

#playlist .playlist-title {
    cursor: pointer;
}
.playlist td{
  vertical-align: middle !important;
}
.playlist tbody {
  border-bottom: 1px solid #ddd !important;
}

/* Main marketing message and sign up button */

#loginPage h1 {
  text-align: center;
  margin-top: 10px;
  font-size: 25px;
  font-family: "Cabin Sketch", cursive;
}
.loginPage .btn {
  font-size: 21px;
  padding: 14px 24px;
}

#loginPage .recaptcha > div {
    margin: 20px auto 0 auto;
}

#historyPage h1 {
  display: inline-block;
}

#historyPage h1 + span {
  display: inline-block;
  margin-left: 15px;
}

/* Supporting marketing content */
.marketing {
  margin: 40px 0 15px 0;
}

.delete-media-history {
  float:right;
  margin-top: 3px;
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
  .container {
    max-width: 730px;
  }

  /* Remove the padding we set earlier */
  .footer {
    padding-left: 0;
    padding-right: 0;
  }
}

/*
 * Top navigation
 * Hide default border to remove 1px line.
 */
.navbar-fixed-top {
  border: 0;
  font-family: "Cabin Sketch", cursive;
  border-radius: 0 25px 225px 0/25px 0 25px 255px;
  border-width: 0 0 2px 0;
}
.nav-log-out-icon {
  float: right;
  margin-left: 6px;
  display: block;
  margin-top: 2px;
}
/*
 * Main content
 */

.main {
  padding-top: 70px;
}
@media (min-width: 768px) {
  .main {
    padding-right: 40px;
    padding-left: 40px;
  }
}
.main .page-header {
  margin-top: 0;
  font-family: "Cabin Sketch", cursive;
  border-bottom: 2px solid #333;
}

/*
 * Sidebar
 */

/* Hide for mobile, show later */
.sidebar {
  display: none;
}
.nav .glyphicon {
  margin-right: 5px;
}
.nav > li.nav-mobile {
  border-bottom: 1px solid #393939;
  color: #ccc;
}
@media (min-width: 768px) {
  .sidebar {
    position: fixed;
    top: 51px;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: block;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    background-color: #f5f5f5;
    background-color: #f5f5f5;
    border-right: 2px solid #f5f5f5;
    border-radius: 0 25px 225px 0/25px 0 25px 255px;
  }
  .nav > li.nav-mobile {
    display: none;
  }
}

/* Video bookmark button */
.video {
  width: 100%;
}
.btn-bookmark {
  padding: 4px 6px !important;
  margin-top: 3px;
  /*border-radius: 255px 25px 225px 25px/25px 225px 25px 255px;*/
}
.btn-bookmark > span {
    top: 2px;
    position: relative;
}
.videoText {
  margin-bottom: 20px;
}
.videoText__title, .settings-subtitle {
  font-size: 2.7rem;
  letter-spacing: -0.01em;
  font-weight: 500;
  font-family: "Cabin Sketch", cursive;
}

/* Sidebar navigation */
.nav-sidebar {
  margin-right: -21px; /* 20px padding + 1px border */
  margin-bottom: 20px;
  margin-left: -20px;
}
.nav-sidebar > li > a {
  padding-right: 20px;
  padding-left: 20px;
  font-family:  "Cabin Sketch", cursive;
}
.nav-sidebar > .active > a,
.nav-sidebar > .active > a:hover,
.nav-sidebar > .active > a:focus {
  color: #fff;
  background-color: #428bca;
  border-radius: 255px 25px 225px 0/25px 225px 25px 255px;
  border-width: 2px 0 2px 0;
}

/* Modals */
.modal-content {
  border-radius: 15px 5px 5px 25px/5px 25px 25px 5px;
}
.modal-content > .modal-header {
  border-bottom: 1px solid #333;
  font-family: "Cabin Sketch", cursive;
  font-size: 22px;
}
.modal-title {
  font-size: 22px;
}
.modal-content .close {
  opacity: 1;
  font-size: 33px;
}

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #333;
}
.modal-footer .btn {
  /*border-radius: 255px 25px 225px 0/25px 225px 25px 255px;*/
  font-family: "Cabin Sketch", cursive;
}
.alert-danger {
  /*border-radius: 255px 25px 225px 25px/25px 225px 25px 255px;*/
  border-width: 2px;
  border-color: #e6cbca;
}
/* Settings */
.btn-settings {
  width: 50% !important;
    font-family: "Cabin Sketch", cursive;
    background-color: #428bca !important;
    border-color: #428bca !important;
}
.settings-blue-note-font {
  color: #428bca;
}
.footnote {
  position: fixed;
  bottom: 20px;
}

.genres-ratings-edit-mode__control {
  width:90%;
}
.genres-ratings-edit-mode__stars {
  float: right;
}
/* Notify */

.watch-later-notify  {
  padding: 15px;
  color: #28a745;
  background-color: #d4edda;
  border-radius: 255px 25px 225px 25px/25px 225px 25px 255px;
  border:2px solid #28a745;
  min-width: 25%;
}
.watch-later-notify__title {
  font-family: "Cabin Sketch", cursive;
}
.watch-later-notify button, .watch-later-notify button:hover {
  color: #28a745;
  opacity: 1 !important;
}
/* Settings */
.settings-movie-genre {
  padding: 16px 0;
  background-color: #fff;
  margin-bottom: 10px;
  /*
  border: 2px solid #333;
  border-radius: 45px 15px 35px 5px/15px 5px 15px 65px;
  */
}
/* Looader */

.spinner {
  margin: 30px auto;
  width: 50px;
  height: 40px;
  font-size: 10px;
}

.spinner > div {
  background-color: #ff7043;
  height: 100%;
  width: 6px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
       transform: scaleY(1.0);
       -webkit-transform: scaleY(1.0);
     }
}
